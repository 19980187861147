import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/login',
      children: [
        // Dashboard
        {
          name: '',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: '',
          path: 'clients',
          component: () => import('@/views/dashboard/pages/Clients'),
        },
        {
          name: '',
          path: 'permissions',
          component: () => import('@/views/dashboard/pages/Permissions'),
        },
        {
          name: '',
          path: 'permissionsdetails',
          component: () => import('@/views/dashboard/pages/PermissionsDetails'),
        },
        {
          name: '',
          path: 'devices',
          component: () => import('@/views/dashboard/pages/Devices'),
        },
        {
          name: '',
          path: 'logs',
          component: () => import('@/views/dashboard/pages/Logs'),
        },
        {
          name: '',
          path: 'user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },

        // {
        //   name: 'Notifications',
        //   path: 'components/notifications',
        //   component: () => import('@/views/dashboard/component/Notifications'),
        // },
        // {
        //   name: 'Icons',
        //   path: 'components/icons',
        //   component: () => import('@/views/dashboard/component/Icons'),
        // },
        // {
        //   name: 'Typography',
        //   path: 'components/typography',
        //   component: () => import('@/views/dashboard/component/Typography'),
        // },
        // // Tables
        // {
        //   name: 'Regular Tables',
        //   path: 'tables/regular-tables',
        //   component: () => import('@/views/dashboard/tables/RegularTables'),
        // },
        // // Maps
        // {
        //   name: 'Google Maps',
        //   path: 'maps/google-maps',
        //   component: () => import('@/views/dashboard/maps/GoogleMaps'),
        // },
        // // Upgrade
        // {
        //   name: 'Upgrade',
        //   path: 'upgrade',
        //   component: () => import('@/views/dashboard/Upgrade'),
        // },
      ]
    },

    {
      path: '/login',
      component: () => import('@/views/dashboard/pages/Login'),
    },
    {
      path: '/admin',
      component: () => import('@/views/dashboard/pages/LoginAdmin'),
    },
    {
      path: '/soon',
      component: () => import('@/views/dashboard/pages/soon'),
    },

    {
      path: '/admin',
      component: () => import('@/views/dashboard/AdminIndex'),
      redirect: '/admin/dashboard',
      children: [
        // Dashboard
        {
          name: '',
          path: 'dashboard',
          component: () => import('@/views/dashboard/DashboardAdmin'),
        },
        // Pages
        {
          name: '',
          path: 'customers',
          component: () => import('@/views/dashboard/pages/Customers'),
        },
        {
          name: '',
          path: 'logs',
          component: () => import('@/views/dashboard/pages/AdminLogs'),
        }
      ]
    },

    {
      path: '/*',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/dashboard',
    },

  ],
})



